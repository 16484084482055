// App.js
import React, { useState } from 'react';
import axios from 'axios';
// import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import './App.css';

const apiUrl = 'https://auth.mobly-dev.com.br/customer';
// const apiUrl = 'http://0.0.0.0:3000/customer';
const xpi = 'ziYMCokAzS3ivsMB9N2bP50qVKBAPpO6aZqj2bRx'

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];

    const token = localStorage.getItem('accessToken');
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.withCredentials = true
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App () {
  const storedAccessToken = localStorage.getItem('accessToken');
  const storedRefreshToken = localStorage.getItem('refreshToken');

  const [accessToken, setAccessToken] = useState(storedAccessToken || null);
  const [refreshToken, setRefreshToken] = useState(storedRefreshToken || null);
  const [fetchError, setFetchError] = useState(null);

  // const setCookieFromBrowser = (ev) => {
  //   // set a cookie in the browser for the current domain
  //   const key = 'score';

  //   const value = encodeURIComponent(
  //     (Math.floor(Math.random() * 123456) + 123456).toString()
  //   );

  //   const thirty = 60 * 60 * 24 * 30;
  //   document.cookie = `${key}=${value};path=/;max-age=${thirty};`; // one cookie at a time
  //   document.getElementById('output').textContent = document.cookie;
  // }

  // const setCookieFromApi = async () => {
  //   // send a request to an API that will create a cookie
  //   let url = `${apiUrl}/get-cookie`;

  //   // const { data } = await axios.post(url, { }, { headers: { 'x-api-key': xpi } });
  //   // console.log('🚀 ~ file: App.js ~ line 41 ~ setCookieFromApi ~ data', data)

  //   let req = new Request(url, {
  //     headers: { 'Content-Type': 'application/json', 'x-api-key': xpi },
  //     method: 'post',
  //     mode: 'cors', // just a safe-guard indicating our intentions of what to allow
  //     credentials: 'include', // when will the cookies and authorization header be sent
  //     body: JSON.stringify({
  //       user: 'jean.charlier+free108@mobly.com.br',
  //       password: '123456'
  //     })
  //   });

  //   fetch(req)
  //     .then((resp) => {
  //       resp.headers.forEach((val, key) => {
  //         console.log(key, val);
  //       });
  //       const cookie = resp.headers.get('set-cookie');
  //       console.log('set-cookie header value', cookie);

  //       return resp.json();
  //     })
  //     .then((data) => {
  //       let p = document.getElementById('output');
  //       console.log(data.message);

  //       p.innerHTML = document.cookie;
  //     })
  //     .catch((err) => {
  //       console.warn(err);
  //       setFetchError(err.message);
  //     });
  // }

  const login = async () => {
    // try {
    //   const { data } = await axios.post(`${apiUrl}/signin`, { user: 'jean.charlier+free108@mobly.com.br', password: '123456' }, { headers: { 'x-api-key': xpi } });
    //   console.log('🚀 ~ file: App.js ~ line 36 ~ login ~ data', data.headers);
    //   // localStorage.setItem('token', data.token);
    //   if (data) {
    //     console.log('🚀 ~ file: App.js ~ line 40 ~ login ~ data', data)
    //     // bake_cookie('AUTH_CUSTOMER_ACCESS_TOKEN', data.accessToken);
    //     // bake_cookie('AUTH_CUSTOMER_REFRESH_TOKEN', data.refreshToken);
    //     setAccessToken(data.accessToken);
    //     setRefreshToken(data.refreshToken);
    //     setFetchError(null);
    //   }
    // } catch (err) {
    //   setFetchError(err.message);
    // }
    // send a request to an API that will create a cookie
    let url = `${apiUrl}/signin`;

    let req = new Request(url, {
      headers: { 'Content-Type': 'application/json', 'x-api-key': xpi },
      method: 'post',
      mode: 'cors', // just a safe-guard indicating our intentions of what to allow
      credentials: 'include', // when will the cookies and authorization header be sent
      body: JSON.stringify({
        user: 'jean.charlier+free108@mobly.com.br',
        password: '123456'
      })
    });

    fetch(req)
      .then((resp) => {
        resp.headers.forEach((val, key) => {
          console.log(key, val);
        });
        const cookie = resp.headers.get('set-cookie');
        console.log('set-cookie header value', cookie);

        return resp.json();
      })
      .then((data) => {
        let p = document.getElementById('output');
        console.log(data.message);

        p.innerHTML = document.cookie;
      })
      .catch((err) => {
        console.warn(err);
        setFetchError(err.message);
      });
  };

  const me = async () => {
    // try {
    //   const { data } = await axios.get(`${apiUrl}/me`, { headers: { Authorization: accessToken, 'x-api-key': xpi } })
    //   console.log('🚀 ~ file: App.js ~ line 49 ~ me ~ data', data)
    //   setFetchError(null);
    // } catch (err) {
    //   setFetchError(err.message);
    // }

    let url = `${apiUrl}/me`;

    let req = new Request(url, {
      headers: { 'x-api-key': xpi },
      mode: 'cors', // just a safe-guard indicating our intentions of what to allow
      credentials: 'include' // when will the cookies and authorization header be sent
    });

    fetch(req)
      .then((resp) => {
        resp.headers.forEach((val, key) => {
          console.log(key, val);
        });
        const cookie = resp.headers.get('set-cookie');
        console.log('set-cookie header value', cookie);

        return resp.json();
      })
      .then((data) => {
        let p = document.getElementById('output');
        console.log(data.message);

        p.innerHTML = document.cookie;
      })
      .catch((err) => {
        console.warn(err);
        setFetchError(err.message);
      });
  };

  const refresh = async () => {
    // try {
    //   const { data } = await axios.post(`${apiUrl}/refresh`, {}, { headers: { Authorization: accessToken, 'x-api-key': xpi, RefreshToken: refreshToken } });
    //   console.log('🚀 ~ file: App.js ~ line 62 ~ refresh ~ data', data)
    //   if (data) {
    //     // bake_cookie('AUTH_CUSTOMER_ACCESS_TOKEN', data.accessToken);
    //     // bake_cookie('AUTH_CUSTOMER_REFRESH_TOKEN', data.refreshToken);
    //     setAccessToken(data.accessToken);
    //     setRefreshToken(data.refreshToken);
    //     setFetchError(null);
    //   }
    // } catch (err) {
    //   setFetchError(err.message);
    // }

    let url = `${apiUrl}/refresh`;

    let req = new Request(url, {
      headers: { 'x-api-key': xpi },
      method: 'post',
      mode: 'cors', // just a safe-guard indicating our intentions of what to allow
      credentials: 'include' // when will the cookies and authorization header be sent
    });

    fetch(req)
      .then((resp) => {
        resp.headers.forEach((val, key) => {
          console.log(key, val);
        });
        const cookie = resp.headers.get('set-cookie');
        console.log('set-cookie header value', cookie);

        return resp.json();
      })
      .then((data) => {
        let p = document.getElementById('output');
        console.log(data.message);

        p.innerHTML = document.cookie;
      })
      .catch((err) => {
        console.warn(err);
        setFetchError(err.message);
      });
  };

  const logout = async () => {
    // try {
    //   const { data } = await axios.post(`${apiUrl}/signout`, {}, { headers: { 'x-api-key': xpi, Authorization: accessToken }});
    //   console.log('🚀 ~ file: App.js ~ line 73 ~ logout ~ data', data)

    //   // delete_cookie('AUTH_CUSTOMER_ACCESS_TOKEN')
    //   // delete_cookie('AUTH_CUSTOMER_REFRESH_TOKEN')
    //   setFetchError(null);
    // } catch (err) {
    //   setFetchError(err.message);
    // }

    let url = `${apiUrl}/signout`;

    let req = new Request(url, {
      headers: { 'x-api-key': xpi },
      method: 'post',
      mode: 'cors', // just a safe-guard indicating our intentions of what to allow
      credentials: 'include' // when will the cookies and authorization header be sent
    });

    fetch(req)
      .then((resp) => {
        resp.headers.forEach((val, key) => {
          console.log(key, val);
        });
        const cookie = resp.headers.get('set-cookie');
        console.log('set-cookie header value', cookie);

        return resp.json();
      })
      .then((data) => {
        let p = document.getElementById('output');
        console.log(data.message);

        p.innerHTML = document.cookie;
      })
      .catch((err) => {
        console.warn(err);
        setFetchError(err.message);
      });
  };

  return (
    <>
      {/* <section style={{ marginBottom: '10px' }}>
        <button onClick={() => setCookieFromBrowser()}>Set Cookie From Browser</button>
      </section> */}
      {/* <section style={{ marginBottom: '10px' }}>
        <button onClick={() => setCookieFromApi()}>Set Cookie From Api</button>
      </section> */}
      <section style={{ marginBottom: '10px' }}>
        <button onClick={() => login()}>Login</button>
      </section>

      <section style={{ marginBottom: '10px' }}>
        <button onClick={() => me()}>/Me</button>
      </section>

      <section style={{ marginBottom: '10px' }}>
        <button onClick={() => refresh()}>Refresh token</button>
      </section>

      <section style={{ marginBottom: '10px' }}>
        <button onClick={() => logout()}>Logout</button>
      </section>

      <section style={{ marginBottom: '10px' }}>
        <h3>Tokens:</h3>
        <h4 style={{ marginLeft: '5px' }}>Access:</h4>
        {accessToken && (
          <pre style={{ marginLeft: '10px' }}>
            <code>{accessToken}</code>
          </pre>
        )}
        <h4 style={{ marginLeft: '5px' }}>Refresh:</h4>
        {refreshToken && (
          <pre style={{ marginLeft: '10px' }}>
            <code>{refreshToken}</code>
          </pre>
        )}
      </section>
      <section>
        <h3>ERRORS:</h3>
        {fetchError && (
          <p style={{ color: 'red' }}>{fetchError}</p>
        )}
      </section>
      <p id='output'></p>
    </>
  );
}
export default App;